export type EventTemplatePermissionsReturnType = {
  canDeleteDraftOrChangeOwner: boolean;
};

export const EventTemplatePermissions = (
  templateStatus: string,
  templateOwnerId: number | null | undefined,
  currentuserId: number,
  currentUserRole: string | undefined,
  hasManageTemplatePermission: boolean,
): EventTemplatePermissionsReturnType => {
  const isDraft = templateStatus === 'draft';
  const isOwner = templateOwnerId === currentuserId;
  const isQuality = currentUserRole === 'quality';
  const canDeleteDraftOrChangeOwner = isDraft && hasManageTemplatePermission && (isOwner || isQuality);
  return { canDeleteDraftOrChangeOwner };
};
