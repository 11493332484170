import React from 'react';
import { FormTemplateWithWorkflowId } from '../../../types';
import EventFormPreview from '../../EventFormPreview/EventFormPreview';
import StartBuildForm from './StartBuildForm';
import { addUniqueIdAttribute } from '../../../utils/objectUtils';

type EventFormPreviewContainerProps = {
  eventFormTemplate: FormTemplateWithWorkflowId;
  isTemplateReadonly: boolean;
};

const EventFormPreviewContainer: React.FC<EventFormPreviewContainerProps> = ({
  eventFormTemplate,
  isTemplateReadonly,
}) => {
  if (eventFormTemplate && eventFormTemplate.fields.length > 0) {
    return <EventFormPreview formFields={eventFormTemplate.fields.map(addUniqueIdAttribute)} />;
  }

  return (
    <StartBuildForm
      eventTemplateId={eventFormTemplate.workflow_id}
      eventFormTemplateId={eventFormTemplate.id}
      isTemplateReadonly={isTemplateReadonly}
    />
  );
};

export default EventFormPreviewContainer;
