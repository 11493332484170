import React, { useCallback } from 'react';
import {
  QModal,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  useCurrentUser,
} from '@qualio/ui-components';
import * as DisplayStrings from '../../displayStrings';
import { useMutation, useQueryClient } from 'react-query';
import { BASE_QUERY_KEY } from '../../views/QualityEventTemplates/hooks';
import templatesApi from '../../api/templates.api';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  lineageId: string;
};

const DeleteDraftEventTemplateConfirmationModal: React.FC<Props> = ({ isOpen, setIsOpen, lineageId }) => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
  };

  const { mutate: deleteDraftTemplateMutate } = useMutation(
    () => templatesApi.deleteDraftTemplate(companyId, lineageId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]).then(() => navigate('/templates'));
      },
    },
  );

  const handleDeleteDraftTemplate = useCallback(async () => {
    try {
      deleteDraftTemplateMutate();
      setIsOpen(false);
      showToast({
        title: 'Draft deleted',
        description: 'The draft was successfully deleted',
        status: 'success',
      });
    } catch (err: any) {
      showToast({
        title: 'Draft not deleted',
        description: 'Failed to delete the draft',
        status: 'error',
      });
    }
  }, [deleteDraftTemplateMutate, setIsOpen, showToast]);

  return (
    <QModal data-cy={'delete-draft-event-template-modal'} isOpen={isOpen} onClose={handleClose} size="md">
      <QModalHeader>
        <QText>{DisplayStrings.DeleteDraftConfirmationHeader}</QText>
      </QModalHeader>
      <QModalBody>
        <QText>{DisplayStrings.DeleteDraftConformationBody}</QText>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          {DisplayStrings.Cancel}
        </QButton>
        <QButton data-cy="delete-draft-confirmation-button" onClick={handleDeleteDraftTemplate}>
          {DisplayStrings.Delete}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default DeleteDraftEventTemplateConfirmationModal;
