import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import {
  QBox,
  QButton,
  QHeading,
  QSpacer,
  QStack,
  QSpinner,
  QHorizontalMetadata,
  QStringMetadataItem,
  QDateMetadataItem,
  useCurrentUser,
  QMenuButton,
  QMenuItem,
} from '@qualio/ui-components';
import { useCreateNewDraftTemplate } from '../../QualityEventTemplates/hooks';
import { useEventTemplateDetails } from '../../../hooks';
import { EventTemplateDetailsResponse } from '../../../types';
import EventTemplateStatusTag from './EventTemplateStatusTag';
import * as DisplayStrings from '../../../displayStrings';
import { useNavigate } from 'react-router-dom';
import { EventTemplatePermissions } from '../../../utils/eventTemplatePermissionUtils';
import { useUserRole } from '../../../hooks/useUserRole';

type EventTemplateHeaderProps = {
  templateId: number;
  isMakeEffectiveBtnDisabled: boolean;
  makeEffectiveBtnAction: () => void;
  saveAndExitBtnAction: () => void;
  deleteDraftBtnAction: () => void;
  changeOwnerBtnAction: () => void;
};

const EventTemplateHeader = ({
  templateId,
  isMakeEffectiveBtnDisabled,
  makeEffectiveBtnAction,
  saveAndExitBtnAction,
  deleteDraftBtnAction,
  changeOwnerBtnAction,
}: EventTemplateHeaderProps) => {
  const { companyId, userId, permissions } = useCurrentUser();
  const { data: template, isLoading: isEventTemplateLoading } = useEventTemplateDetails(companyId, templateId);
  const { mutate: createNewDraftTemplate } = useCreateNewDraftTemplate(companyId);
  const navigate = useNavigate();
  const role = useUserRole();

  const templateData = template as EventTemplateDetailsResponse;

  const showMakeEffectiveSaveAndExitBtn = useMemo(
    () => templateData && templateData.status === 'draft' && templateData.owner_id === userId,
    [templateData, userId],
  );

  if (isEventTemplateLoading) {
    return <QSpinner />;
  }

  const { canDeleteDraftOrChangeOwner } = EventTemplatePermissions(
    templateData.status,
    templateData.owner_id,
    userId,
    role,
    Boolean(permissions['can_manage_quality_event_workflow']),
  );
  return (
    <>
      <QBox>
        <QButton variant="link" onClick={() => navigate('/templates')} leftIcon="ArrowLeft">
          {DisplayStrings.Back}
        </QButton>
      </QBox>
      <QStack direction="row" mb={1}>
        <QBox>
          <QHeading data-cy="event-template-name" size="lg">
            {templateData.name}
          </QHeading>
        </QBox>
        <QSpacer />
        {templateData.status === 'effective' && templateData.active && (
          <QButton
            data-cy="create-new-draft"
            variant="outline"
            onClick={() => createNewDraftTemplate(templateData.lineage_id!)}
          >
            {DisplayStrings.CreateNewDraft}
          </QButton>
        )}
        {showMakeEffectiveSaveAndExitBtn && (
          <>
            <QButton data-cy={'save-and-exit'} variant="outline" onClick={saveAndExitBtnAction}>
              {DisplayStrings.SaveAndExit}
            </QButton>
            <QButton
              data-cy={'make-effective'}
              isDisabled={isMakeEffectiveBtnDisabled}
              onClick={makeEffectiveBtnAction}
            >
              {DisplayStrings.MakeEffective}
            </QButton>
          </>
        )}
        <QMenuButton buttonLabel="event-template-kebab-menu" iconName="MoreVertical" itemSize="sm" variant="icon">
          <QMenuItem
            data-cy="change-owner"
            onClick={changeOwnerBtnAction}
            aria-label="change-owner"
            isDisabled={!canDeleteDraftOrChangeOwner}
          >
            {DisplayStrings.ChangeTemplateOwner}
          </QMenuItem>
          <QMenuItem
            data-cy="delete-draft-template"
            onClick={deleteDraftBtnAction}
            aria-label="delete-draft-template"
            isDisabled={!canDeleteDraftOrChangeOwner}
          >
            {DisplayStrings.DeleteDraft}
          </QMenuItem>
        </QMenuButton>
      </QStack>
      <QHorizontalMetadata>
        <EventTemplateStatusTag status={templateData.active ? templateData.status : 'archived'} />
        <QStringMetadataItem label="Version" value={`${templateData.major_version}.${templateData.minor_version}`} />
        <QStringMetadataItem label="Template owner" value={templateData.owner ?? '--'} />
        <QDateMetadataItem
          label="Last modified"
          value={templateData.updated_at ? DateTime.fromSeconds(templateData.updated_at).toJSDate() : undefined}
          data-cy="event-template-last-modified"
          withTime
        />
      </QHorizontalMetadata>
    </>
  );
};

export default EventTemplateHeader;
