import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  QAlert,
  QBox,
  QButton,
  QDivider,
  QFlex,
  QHeading,
  QStack,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import * as DisplayStrings from '../../../displayStrings';
import { useLocalisedFormatDateTime } from '../../../utils/datetimeUtils';
import EventFormDetailsMenu from './EventFormDetailsMenu';
import { UseQueryResult } from 'react-query';
import { Approver, ApproverType, Form, UpdateForm } from '../../../types';
import ApprovalRequestBanner from './ApprovalRequestBanner';
import ApprovalDeclinedBannerDesc from './ApprovalDeclinedBannerDesc';
import ApproveDeclineModal from './ApproveDeclineModal';
import DigitalSignatureModal from '../../../components/DigitalSignatureModal/DigitalSignatureModal';
import ReviewRequestBanner from './ReviewRequestBanner';
import MarkAsReviewedModal from './MarkAsReviewedModal';
import { useEventFormEdit, useFormApproval, useMarkAsReviewed } from '../../../hooks';
import FormStatusBadge from '../../../components/FormStatusBadge';
import { useFlags } from '../../../external/LaunchDarklyAdapter';

type EventFormHeaderProps = {
  eventId: number;
  form: Form;
  assignedApprovers: Approver[];
  assignedReviewers: Approver[];
  formRefetch: () => Promise<UseQueryResult>;
  editMode?: boolean;
  setApproverType: React.Dispatch<React.SetStateAction<ApproverType>>;
  setIsApproverModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAssignFormModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentUserCompanyId: number | undefined;
};

const EventFormHeader: React.FC<EventFormHeaderProps> = ({
  eventId,
  form,
  assignedApprovers,
  assignedReviewers,
  formRefetch,
  setApproverType,
  setIsApproverModalOpen,
  setIsAssignFormModalOpen,
  editMode = false,
  currentUserCompanyId,
}) => {
  const navigate = useNavigate();
  const formatDateTime = useLocalisedFormatDateTime();
  const { showToast } = useToastProvider();
  const { userId } = useCurrentUser();
  const [currentModal, setCurrentModal] = useState<'approve' | 'decline' | 'review' | null>(null);
  const useNewSignatureModal = useFlags('qeOnepassTesting');
  const stepId = form.issue_step_id;
  const formId = form.id;

  const { editEventFormMutate } = useEventFormEdit(eventId, form.issue_step_id, form.id, () => {
    formRefetch().then();
  });

  const displaySuccessToast = (title: string, message: string, onlyShowMessage = false) => {
    showToast({
      title: title,
      description: onlyShowMessage ? (
        <span>{message}</span>
      ) : (
        <span>
          <b>{form.title}</b> {message}
        </span>
      ),
      status: 'success',
    });
  };

  const displayErrorToast = (title: string, description: string, onClick: () => void, onClickText: string) => {
    showToast({
      title: title,
      description: (
        <span>
          {description}
          <button style={{ color: '#3D4CF2' }} onClick={onClick}>
            {onClickText}
          </button>
        </span>
      ),
      status: 'error',
    });
  };

  const validateAction = (actionUsersType: 'approvers' | 'reviewers', actionUsers: Approver[], onClick: () => void) => {
    if (!(actionUsers && actionUsers.length > 0)) {
      displayErrorToast(
        `Missing ${actionUsersType}`,
        `This step has no ${actionUsersType}. `,
        onClick,
        `Manage ${actionUsersType}`,
      );
      return false;
    }

    if (actionUsersType === 'approvers' && !actionUsers.map((u) => u.user_type).includes('quality')) {
      displayErrorToast(
        'Missing quality type approver',
        'Please include at least one quality user for approval. ',
        onClick,
        `Manage ${actionUsersType}`,
      );
      return false;
    }

    const mandatoryFields = form.fields.filter((field) => field.mandatory);
    if (mandatoryFields.filter((field) => field.values?.length === 0).length > 0) {
      displayErrorToast('Missing information', 'Please answer all required questions. ', editAction, 'Edit');
      return false;
    }
    return true;
  };

  const sendForApprovalAction = () => {
    if (validateAction('approvers', assignedApprovers, manageApproversAction)) {
      editEventFormMutate(
        { status: 'for_approval' },
        {
          onSuccess: () => {
            displaySuccessToast('Sent for approval', 'was sent for approval');
          },
        },
      );
    }
  };

  const sendForReviewAction = () => {
    if (validateAction('reviewers', assignedReviewers, manageReviewersAction)) {
      editEventFormMutate(
        { status: 'for_review' },
        {
          onSuccess: () => {
            displaySuccessToast('Sent for review', 'was sent for review');
          },
        },
      );
    }
  };

  const editAction = () => {
    navigate(`/events/${eventId}/steps/${form.issue_step_id}/forms/${form.id}/edit`);
  };

  const reassignStepAction = () => setIsAssignFormModalOpen(true);

  const manageReviewersAction = () => {
    setApproverType('reviewers');
    setIsApproverModalOpen(true);
  };

  const manageApproversAction = () => {
    setApproverType('approvers');
    setIsApproverModalOpen(true);
  };

  const revertToDraftAction = () => {
    editEventFormMutate(
      { status: 'draft' },
      {
        onSuccess: (data: UpdateForm) => {
          navigate(`/events/${eventId}/steps/${data.issue_step_id}/forms/${data.id}`);
          displaySuccessToast('Reverted to draft', 'was reverted to draft');
        },
      },
    );
  };

  const viewHeaderButtons = !editMode && userId === form.owner_id;
  const viewSendForApprovalButton = viewHeaderButtons && ['draft', 'for_review'].includes(form.status);
  const viewEditButton = viewHeaderButtons && form.status === 'draft';
  const viewRevertToDraftButton = viewHeaderButtons && form.status === 'approval_declined';

  const ifShowMarkAsReviewedActionBanner = useMemo(
    () =>
      assignedReviewers.some(
        (reviewer) => reviewer.usercompany_id === currentUserCompanyId && reviewer.action_status === 'pending',
      ) && form.status === 'for_review',
    [assignedReviewers, currentUserCompanyId, form.status],
  );

  const ifShowApproveDeclineActionBanner = useMemo(
    () =>
      assignedApprovers.some(
        (approver) => approver.usercompany_id === currentUserCompanyId && approver.action_status === 'pending',
      ) && form.status === 'for_approval',
    [assignedApprovers, currentUserCompanyId, form.status],
  );

  const approverDeclined = useMemo(() => {
    return assignedApprovers.find((approver) => approver.action_status === 'declined');
  }, [assignedApprovers]);

  const { mutate: markAsReviewedMutate, isLoading: isMarkAsReviewedLoading } = useMarkAsReviewed(
    eventId,
    stepId,
    formId,
    () => {
      setCurrentModal(null);
    },
  );

  const { mutate: formApprovalMutate, isLoading: isFormApprovalLoading } = useFormApproval(
    eventId,
    stepId,
    formId,
    () => {
      setCurrentModal(null);
    },
  );

  const backLink = editMode ? `/events/${eventId}/steps/${stepId}/forms/${formId}` : `/events/${eventId}`;

  return (
    <QBox pb={3}>
      <QBox>
        <QButton variant="link" onClick={() => navigate(backLink)} leftIcon="ArrowLeft">
          {DisplayStrings.Back}
        </QButton>
      </QBox>
      <QStack direction={['row', 'column']} spacing="0" pb={2} pt={2}>
        <QFlex width={'100%'}>
          <QHeading as="h2" size="md" color="gray.900" fontWeight={600} width={'100%'}>
            {form.title}
          </QHeading>
          <QStack width={'100%'} direction="row-reverse" spacing={4}>
            {viewRevertToDraftButton && (
              <QButton onClick={revertToDraftAction} aria-label="revert-to-draft" data-cy="revert-to-draft">
                Revert to draft
              </QButton>
            )}
            {viewSendForApprovalButton && (
              <QButton onClick={sendForApprovalAction} aria-label="send-for-approval" data-cy="send-for-approval">
                Send for approval
              </QButton>
            )}
            {viewEditButton && (
              <QButton onClick={editAction} aria-label="edit-form" data-cy="edit-form" variant="outline">
                Edit
              </QButton>
            )}

            <EventFormDetailsMenu
              formStatus={form.status}
              formOwnerId={form.owner_id}
              editMode={editMode}
              onSendForReview={sendForReviewAction}
              onReassignStep={reassignStepAction}
              onManageReviewers={manageReviewersAction}
              onManageApprovers={manageApproversAction}
              onRevertToDraft={revertToDraftAction}
            />
          </QStack>
        </QFlex>
      </QStack>
      <QStack direction={['column', 'row']} pb={6}>
        <QText fontSize="xs">
          <FormStatusBadge formStatus={form.status} />
        </QText>
        <QText>&middot;</QText>
        <QText fontSize="sm" color="gray.500">
          <b>Created </b>
          {formatDateTime(form.created_at)}
        </QText>
        <QText>&middot;</QText>
        <QText fontSize="sm" color="gray.500">
          <b>Last modified </b>
          {formatDateTime(form.updated_at)}
        </QText>
      </QStack>
      {ifShowMarkAsReviewedActionBanner && (
        <ReviewRequestBanner markAsReviewedButtonClick={() => setCurrentModal('review')} />
      )}
      {ifShowApproveDeclineActionBanner && (
        <ApprovalRequestBanner
          approveButtonClick={() => setCurrentModal('approve')}
          declineButtonClick={() => setCurrentModal('decline')}
        />
      )}
      {form.status === 'approval_declined' && approverDeclined && (
        <QAlert
          title="Approve declined"
          description={
            <ApprovalDeclinedBannerDesc
              approverDeclinedFullName={approverDeclined.full_name}
              approverDeclinedActionTimestamp={approverDeclined.action_timestamp}
              approverDeclinedComment={approverDeclined.approval_comment}
            />
          }
          isInline
          status="error"
          mb={4}
        />
      )}
      <QDivider />
      {useNewSignatureModal ? (
        <DigitalSignatureModal
          isOpen={currentModal === 'approve'}
          setIsOpen={() => setCurrentModal(null)}
          onSave={async ({ password, comment }) => formApprovalMutate({ password, comment, isApprove: true })}
          isCommentRequired={false}
          inputTexts={{
            headingText: 'Approve step',
            subText: `Enter your digital signature to approve the step`,
            submitButtonText: 'Approve',
          }}
          displayToast={false}
        />
      ) : null}
      {useNewSignatureModal ? (
        <DigitalSignatureModal
          isOpen={currentModal === 'decline'}
          setIsOpen={() => setCurrentModal(null)}
          onSave={async ({ password, comment }) => formApprovalMutate({ password, comment, isApprove: false })}
          isCommentRequired={true}
          isSubmitButtonDestructive={true}
          inputTexts={{
            headingText: 'Decline step',
            subText: `Enter your digital signature to decline the step`,
            submitButtonText: 'Decline',
          }}
          displayToast={false}
        />
      ) : null}
      {!useNewSignatureModal ? (
        <ApproveDeclineModal
          isOpen={currentModal === 'approve' || currentModal === 'decline'}
          isSubmitBtnLoading={isFormApprovalLoading}
          onSubmit={formApprovalMutate}
          setIsOpen={() => setCurrentModal(null)}
          action={currentModal === 'approve' ? 'Approve' : 'Decline'}
        />
      ) : null}
      <MarkAsReviewedModal
        isOpen={currentModal === 'review'}
        isSubmitBtnLoading={isMarkAsReviewedLoading}
        onSubmit={markAsReviewedMutate}
        onClose={() => setCurrentModal(null)}
        formTitle={form.title}
      />
    </QBox>
  );
};

export default EventFormHeader;
