import * as z from 'zod';

export const EventTemplateStatusSchema = z.enum([
  'draft',
  'effective',
  'archived',
  'for_approval',
  'approval_declined',
  'superseded',
]);
export type EventTemplateStatus = z.infer<typeof EventTemplateStatusSchema>;
