import React from 'react';
import { useNavigate } from 'react-router-dom';
import { QBox, QBadge, QSpinner, QTag, QText, deprecated } from '@qualio/ui-components';
import { Task } from '../../types/eventTask';
import { EventSortParam } from '../../types';
import DueColumn from '../DueColumn/DueColumn';
import { DateTime } from 'luxon';
import { convertStringToDateTime } from '../../utils/datetimeUtils';

type TaskListProps = {
  tasks: Task[] | undefined;
  isLoading?: boolean;
  pageCount: number;
  totalItems: number;
  paginationCallback: (pageIndex: number, pageSize: number) => void;
  sortByCallback: (sortBy: unknown[]) => void;
  initialPageIndex: number;
  initialPageSize: number;
  sortByColumn: EventSortParam[];
};

const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'code',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Event ID',
    accessor: 'issue_code',
  },
  {
    Header: 'Assignee',
    accessor: 'owner',
  },
  {
    Header: 'Due',
    accessor: 'due_at',
  },
];

const mapStatusToColor = (status: string) => {
  switch (status) {
    case 'open':
      return 'blue';
    case 'closed_success':
      return 'green';
    case 'closed_fail':
      return 'red';
    default:
      return 'default';
  }
};

const mapStatusToLabel = (status: string) => {
  switch (status) {
    case 'open':
      return 'Open';
    case 'closed_success':
      return 'Completed';
    case 'closed_fail':
      return 'Canceled';
    default:
      return status;
  }
};

const TaskList: React.FC<TaskListProps> = ({
  tasks,
  isLoading,
  pageCount,
  totalItems,
  paginationCallback,
  sortByCallback,
  initialPageIndex,
  initialPageSize,
  sortByColumn,
}) => {
  const navigate = useNavigate();
  const today = DateTime.now();
  const mappedData = tasks?.map((task: Task) => ({
    id: task.id || '',
    title: task.title || '',
    issue_code: <QBadge textTransform="none">{task.issue_code}</QBadge> || '',
    code: <QBadge textTransform="none">{task.code}</QBadge> || '',
    owner: task.owner_full_name || '',
    due_at: (
      <DueColumn
        referenceDatetime={today}
        createdAtDatetime={convertStringToDateTime(task.created_at)}
        timeLimit={task.time_limit}
        closedAtDatetime={task.closed_at ? convertStringToDateTime(task.closed_at) : undefined}
        isClosed={['closed_success', 'closed_fail'].includes(task.status)}
      />
    ),
    status: task.status ? (
      <QTag variantColor={mapStatusToColor(task.status || '')}>{mapStatusToLabel(task.status)}</QTag>
    ) : (
      ''
    ),
    issue_id: task.issue_id,
  }));

  const handleClick = (row: deprecated.QDataRow) => {
    navigate({
      pathname: `/events/${row.issue_id}`,
      search: `?task_id=${row.id}`,
    });
  };

  return (
    <>
      <QBox>
        {isLoading ? (
          <QBox w="100%" textAlign="center" p={5} mt={16}>
            <QSpinner />
          </QBox>
        ) : (
          <>
            <QText mb={1} weight="semibold" color={'gray.500'}>
              {totalItems === 1 ? `${totalItems} task` : `${totalItems} tasks`}
            </QText>
            <deprecated.QDataTable
              onRowClick={handleClick}
              columns={COLUMNS}
              data={mappedData || []}
              hasPageSizeOptions={true}
              manualPagination={{ paginationCallback, pageCount, initialPageIndex, initialPageSize }}
              manualSortBy={{ sortByCallback, defaultSortByColumn: sortByColumn }}
            />
          </>
        )}
      </QBox>
    </>
  );
};

export default TaskList;
