import React from 'react';
import { QButton, QCenter, QHeading, QStack, QText } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';
import * as DisplayStrings from '../../../displayStrings';

type StartBuildFormProps = {
  eventTemplateId: number;
  eventFormTemplateId: number;
  isTemplateReadonly: boolean;
};

const StartBuildForm: React.FC<StartBuildFormProps> = ({
  eventTemplateId,
  eventFormTemplateId,
  isTemplateReadonly,
}) => {
  const navigate = useNavigate();

  const goToEditForm = () => {
    navigate(`../templates/${eventTemplateId}/form-templates/${eventFormTemplateId}/edit`);
  };

  return (
    <QStack direction={'column'} spacing={5}>
      <QCenter pt={10}>
        <QHeading size="md">{DisplayStrings.NoFormYet}</QHeading>
      </QCenter>
      {!isTemplateReadonly && (
        <>
          <QCenter>
            <QText w="60%" textAlign="center" fontSize="sm">
              {DisplayStrings.StartFormCopy}
            </QText>
          </QCenter>
          <QCenter pt={5}>
            <QButton onClick={() => goToEditForm()}>{DisplayStrings.StartBuildingForm}</QButton>
          </QCenter>
        </>
      )}
    </QStack>
  );
};

export default StartBuildForm;
