import React, { useCallback, useState } from 'react';
import { QBox, QIconButton, QTooltip, useReferenceDrawer, QriAttributes } from '@qualio/ui-components';
import { useFlags } from '../../../../external/LaunchDarklyAdapter';

export const LinkedResourcesButton = ({
  eventTitle,
  companyId,
  eventId,
}: {
  eventTitle: string;
  companyId: string;
  eventId: string;
}) => {
  const qualityEventsReferenceDrawer = useFlags('qualityEventsReferenceDrawer');

  const [qri, setQri] = useState<QriAttributes | undefined>();

  const onCloseHandler = () => {
    setQri(undefined);
  };
  useReferenceDrawer(
    {
      qri,
      isLoading: false,
      label: eventTitle,
      displayViewDetailsLink: false,
    },
    onCloseHandler,
  );

  const onClick = useCallback(() => {
    setQri({
      companyId: companyId,
      domain: 'quality-events',
      domainType: 'quality-event',
      id: eventId,
    });
  }, [companyId, eventId]);

  if (!qualityEventsReferenceDrawer) {
    return null;
  }

  return (
    <QTooltip label="View links" placement="top">
      <QBox>
        <QIconButton onClick={onClick} iconName="Link2" aria-label="Linked resources" />
      </QBox>
    </QTooltip>
  );
};
