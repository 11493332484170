import * as z from 'zod';

export const QriDetailsSchema = z.object({
  identifiers: z.object({
    id: z.string(),
    code: z
      .string()
      .nullish()
      .transform((x) => x ?? null),
    qri: z.string(),
  }),
  resource: z.string(),
  name: z.string(),
  displayLabel: z.string(),
  domain: z.string(),
  urls: z.object({
    view: z
      .string()
      .nullish()
      .transform((x) => x ?? null),
    data: z.string(),
  }),
  resourceSubType: z.string().optional(),
  resourceSubTypeName: z.string().nullish(),
});

export const QriBulkDetailsSchema = z.record(z.string(), QriDetailsSchema);

export type QriDetails = z.infer<typeof QriDetailsSchema>;

export type QriBulkDetails = z.infer<typeof QriBulkDetailsSchema>;
