import React from 'react';
import { QBox, useCurrentUser, createQColumnHelper, QDataTable, TableMenuItem } from '@qualio/ui-components';
import { UserTask } from '../../../types';
import { canCompleteTaskFn } from '../../../context';
import { calcTaskDueDate } from '../../../utils/datetimeUtils';

const mapTaskStatusToLabel = (status: string) => {
  switch (status) {
    case 'open':
      return 'Open';
    case 'closed_success':
      return 'Completed';
    case 'closed_fail':
      return 'Canceled';
    default:
      return status;
  }
};

const isTaskOpen = (task: UserTask) => task.status === 'open';
const isTaskClosed = (task: UserTask) => task.status === 'closed_success' || task.status === 'closed_fail';

export type SortByType = { id: string; desc?: boolean }[];

type StepTasksListProps = {
  setIsCompleteModalOpen: (status: boolean) => void;
  setSelectedTask: (task: UserTask) => void;
  setIsDrawerOpen: (status: boolean) => void;
  isEventOwner: boolean;
};

const StepTasksList = ({
  setSelectedTask,
  setIsCompleteModalOpen,
  setIsDrawerOpen,
  isEventOwner,
}: StepTasksListProps) => {
  const { userId } = useCurrentUser();

  const columnHelper = createQColumnHelper<UserTask>();
  const columns = [
    columnHelper.textAction(
      'title',
      (task: UserTask) => {
        setSelectedTask(task);
        setIsDrawerOpen(true);
      },
      { id: 'title', header: 'Task name' },
    ),
    columnHelper.status((task: UserTask) => mapTaskStatusToLabel(task.status), {
      id: 'status',
      header: 'Status',
      statuses: { Open: 'blue', Completed: 'green', Canceled: 'red' },
      maxWidth: '15%',
    }),
    columnHelper.text('owner', { id: 'owner', header: 'Assigned to', minWidth: '25%' }),
    columnHelper.date((task: UserTask) => calcTaskDueDate(task.created_at, task.time_limit), {
      id: 'due',
      header: 'Due',
      maxWidth: '15%',
      highlightPastDates: (task: UserTask) => isTaskOpen(task),
      enableSorting: false,
    }),
    columnHelper.menu({
      items: (
        <TableMenuItem
          onClick={(task: UserTask) => {
            setSelectedTask(task);
            setIsCompleteModalOpen(true);
          }}
          isDisabled={(task: UserTask) => isTaskClosed(task) && canCompleteTaskFn(userId, task, isEventOwner)}
        >
          Complete
        </TableMenuItem>
      ),
    }),
  ];

  return (
    <QBox p={2}>
      <QDataTable columns={columns} />
    </QBox>
  );
};

export default StepTasksList;
