import React from 'react';
import {
  QBox,
  QButton,
  QLookup,
  QModalHeader,
  QResourceLabel,
  QSpinner,
  QText,
  QTitle,
  SearchDomain,
  SearchResource,
} from '@qualio/ui-components';
import { useQriBulkDetails } from '../../../../hooks';
import TextOr from '../../../TextOr/TextOr';
import {
  QLookupStandardValueObject,
  StandardValueObject,
  SubComponentProps,
  ViewModeDictionary,
} from '../../EventFormField.types';
import ReadonlyWrapper from '../ReadOnlyWrapper';
import * as DisplayStrings from '../../../../displayStrings';
import { globalSearchApi, qriApi } from '../../../../api';
import { ViewMap } from './DataView';
import { formResolvedQriDisplayText } from './selectedItemText';
import { useFlags } from '../../../../external/LaunchDarklyAdapter';
import { useReferenceDrawer } from '../../../../hooks/useReferenceDrawer';
import { fieldTypeIsQObjectType } from '../../../../utils/eventFormUtils';
import { QObjectFieldType } from '../../../../types/formFields/common';
import { ResourceLabelThatTriggersReferenceDrawer } from '../ResourceLabelToTriggerReferenceDrawer';

/**
 * resourceMap is used to convert a "FieldType" to the appropriate search "resource"
 * for quicksearch V2
 */
const resourceMap = {
  event: 'quality-event',
  document: 'document',
  user: 'user',
  supplier: 'supplier',
  change_request: 'change-request',
  registry: 'registry-item',
  design_controls: ['dc-requirement', 'dc-testCase', 'dc-change-control', 'dc-risk'],
} as const satisfies Record<QObjectFieldType, SearchResource | readonly SearchResource[]>;

const domainMap = {
  event: 'quality-events',
  document: 'documents',
  user: 'users',
  supplier: 'suppliers',
  change_request: 'change-requests',
  registry: 'registry',
  design_controls: 'design-controls',
} as const satisfies Record<QObjectFieldType, SearchDomain>;

const QObjectField = (props: SubComponentProps) => {
  const { fieldValues, inputType, onChange, fieldId, mode, isEditModalOpen, setIsEditModalOpen, resourceSubType } =
    props;
  const valueObjects = fieldValues as StandardValueObject[];
  const parsedValueObjects = valueObjects.map((valueObject) => {
    return JSON.parse(valueObject.value) as QLookupStandardValueObject;
  });
  const qris = parsedValueObjects.map((value) => value.qri);
  const { data: qrisBulkDetails, isLoading: isQrisDetailsLoading } = useQriBulkDetails(qris);
  const isQriReferenceDrawerEnabled = useFlags('qriReferenceDrawer');

  const referenceDrawerProps = useReferenceDrawer(isQriReferenceDrawerEnabled);

  if (!fieldTypeIsQObjectType(inputType)) {
    console.log('QObjectField was called with an unsupported field type');
    return null;
  }

  const SelectComponent = props.multi ? QLookup.MultiSelect : QLookup.SingleSelect;
  const btnOnClick = () => setIsEditModalOpen(true);
  const domain = domainMap[inputType];

  return (
    <>
      {mode === ViewModeDictionary.READONLY && valueObjects.length === 0 && (
        <ReadonlyWrapper>
          <TextOr />
        </ReadonlyWrapper>
      )}
      {mode !== ViewModeDictionary.READONLY && valueObjects.length === 0 && (
        <QButton data-cy={`add-${inputType}-button`} variant="link" onClick={btnOnClick}>
          {DisplayStrings.Add}
        </QButton>
      )}
      <QBox mt={2}>
        {isQrisDetailsLoading ? (
          <QBox w="100%" textAlign="center">
            <QSpinner />
          </QBox>
        ) : (
          parsedValueObjects.map((value: QLookupStandardValueObject) => {
            if (!qrisBulkDetails) {
              return null;
            }
            const qriDetails = qrisBulkDetails[value.qri];
            if (!qriDetails) {
              console.log(`QRI details for ${value.qri} not found`);
              return null;
            }

            if (isQriReferenceDrawerEnabled && referenceDrawerProps) {
              return (
                <ResourceLabelThatTriggersReferenceDrawer
                  key={`q-resource-${value.native_id}`}
                  resourceDomain={domain}
                  handleQResourceLabelClick={referenceDrawerProps.handleQResourceLabelClick}
                  qriDetails={qriDetails}
                  mode={mode}
                />
              );
            }

            return (
              <QResourceLabel
                key={`q-resource-${value.native_id}`}
                domain={domain}
                link={domain === 'users' ? undefined : qriDetails.identifiers.qri}
                openInNewTab={true}
              >
                <QText fontSize="sm">{formResolvedQriDisplayText(qriDetails)}</QText>
              </QResourceLabel>
            );
          })
        )}
      </QBox>
      {isEditModalOpen ? (
        <QLookup.DataProvider.QuickSearchV2
          resource={resourceMap[inputType]}
          resourceSubType={resourceSubType ?? undefined}
          quickSearchClient={globalSearchApi}
          qriClient={qriApi}
          defaultDataQRIs={qris}
        >
          <SelectComponent
            accessors={{ id: 'id' }}
            isItemPreSelected={(item: QLookup.DataProvider.V2Result) => {
              return !!qris.find((qri) => qri === item.identifiers.qri);
            }}
            shouldPin={true}
            onSelect={async (selection) => {
              onChange(
                selection.map((selected) => {
                  const existingValue = parsedValueObjects.find(
                    (fieldVal) => fieldVal.qri === selected.identifiers.qri,
                  );
                  return {
                    ...existingValue,
                    value: JSON.stringify({
                      native_id: selected.id,
                      qri: selected.identifiers.qri,
                      domain: selected.domain,
                    }),
                    form_field_id: fieldId,
                  };
                }),
              );
              setIsEditModalOpen(false);
            }}
            onCancel={() => {
              setIsEditModalOpen(false);
            }}
            action={DisplayStrings.ApplyChanges}
            view={ViewMap[inputType]}
            isOpen={isEditModalOpen}
          >
            <QModalHeader>
              <QTitle>{DisplayStrings.Add}</QTitle>
            </QModalHeader>
          </SelectComponent>
        </QLookup.DataProvider.QuickSearchV2>
      ) : null}
    </>
  );
};

export default QObjectField;
