import React from 'react';
import {
  EventTemplateField,
  EventTemplateFieldAttributeName,
  EventTemplateFieldAttributeNames,
} from '../../types/eventField';
import { QLink, QText, QTable, QThead, QTr, QTh, QTbody, QTd, QSwitch } from '@qualio/ui-components';
import { EventTemplateFormValues } from '../../types';
import * as DisplayStrings from '../../displayStrings';

type TemplateFieldsListProps = {
  templateFields: EventTemplateFormValues['fields'];
  setTemplateFields: (templateFields: EventTemplateFormValues['fields']) => void;
  isTemplateReadonly: boolean;
};

type Option = {
  label: string;
  description: string;
  linkHref?: string;
  linkText?: string;
  attributeName: EventTemplateFieldAttributeName;
};

const FieldOptions: Option[] = [
  {
    label: 'Product',
    description: 'Select the product associated with this event.',
    attributeName: EventTemplateFieldAttributeNames.Enum.product,
  },
  {
    label: 'Root cause',
    description: 'Select the root cause of this event.',
    attributeName: EventTemplateFieldAttributeNames.Enum.root_cause,
  },
  {
    label: 'Risk',
    description: 'Risk is calculated based on the probability and severity fields of an event. ',
    linkText: 'Please see our documentation for more details.',
    linkHref: 'https://docs.qualio.com/en/articles/6644756-events-advanced-details-functionality#h_3b5d997a7d',
    attributeName: EventTemplateFieldAttributeNames.Enum.risk,
  },
  {
    label: 'Related suppliers',
    description: 'Link suppliers that are related to this event. ',
    attributeName: EventTemplateFieldAttributeNames.Enum.supplier,
  },
];

const TemplateFieldsList = ({ templateFields, setTemplateFields, isTemplateReadonly }: TemplateFieldsListProps) => {
  const createOrUpdateField = (attributeName: EventTemplateFieldAttributeName, mandatory: boolean) => {
    const originalField = templateFields[attributeName] ? templateFields[attributeName] : {};
    const newField: EventTemplateField = {
      ...originalField,
      attribute_name: attributeName,
      mandatory,
    };

    const templateFieldsCopy = { ...templateFields, [attributeName]: newField };

    setTemplateFields(templateFieldsCopy);
  };

  const handleSetVisible = (
    event: React.ChangeEvent<HTMLInputElement>,
    attributeName: EventTemplateFieldAttributeName,
  ) => {
    if (event.target.checked) {
      createOrUpdateField(attributeName, false);
    } else {
      const templateFieldsCopy = { ...templateFields };

      delete templateFieldsCopy[attributeName];

      setTemplateFields(templateFieldsCopy);
    }
  };

  return (
    <>
      <QText color="gray.500" fontSize="sm">
        {DisplayStrings.TemplateFieldControlDescription}
      </QText>
      <QTable>
        <QThead>
          <QTr key="th1">
            <QTh key={1} width="150px">
              Field title
            </QTh>
            <QTh key={2}>Description</QTh>
            <QTh key={3}>Visible</QTh>
            <QTh key={4}>Required</QTh>
          </QTr>
        </QThead>
        <QTbody>
          {FieldOptions.map(({ attributeName, label, description, linkHref, linkText }) => {
            const isVisible = Boolean(templateFields[attributeName]);
            const isMandatory = isVisible ? templateFields[attributeName]!.mandatory : false;

            return (
              <QTr key={attributeName}>
                <QTd>{label}</QTd>
                <QTd>
                  {description}
                  {linkHref && linkText ? (
                    <QLink isExternal href={linkHref} isCrossMFE={false}>
                      {linkText}
                    </QLink>
                  ) : null}
                </QTd>
                <QTd>
                  <QSwitch
                    data-cy={`EventTemplate-fieldVisible-${attributeName}`}
                    onChange={(e) => handleSetVisible(e, attributeName)}
                    isChecked={isVisible}
                    isDisabled={isTemplateReadonly}
                  />
                </QTd>
                <QTd>
                  <QSwitch
                    data-cy={`EventTemplate-fieldMandatory-${attributeName}`}
                    isDisabled={isTemplateReadonly || !isVisible}
                    onChange={(e) => createOrUpdateField(attributeName, e.target.checked)}
                    isChecked={isMandatory}
                  />
                </QTd>
              </QTr>
            );
          })}
        </QTbody>
      </QTable>
    </>
  );
};

export default TemplateFieldsList;
