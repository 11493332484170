import { EventTemplateDetailsResponse, EventTemplateFormValues } from '../types';
import { elementAtIndex } from './arrayUtils';

export const convertResponseToTemplateFormValues = (data: EventTemplateDetailsResponse): EventTemplateFormValues => {
  const { name, prefix, status, lineage_id, default_time_limit, steps, fields, default_owner_id } = data;
  const start_time_constraint = Number(elementAtIndex(steps, steps.length - 1).start_time_constraint);

  return {
    name,
    prefix,
    status,
    lineage_id,
    default_time_limit,
    start_time_constraint,
    fields: fields.reduce(
      (acc, field) => {
        acc[field.attribute_name] = field;
        return acc;
      },
      {} as EventTemplateFormValues['fields'],
    ),
    steps: steps,
    default_owner_id: default_owner_id ? `${default_owner_id}` : '',
  };
};
