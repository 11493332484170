import * as z from 'zod';
import { DateSchema, NumberSchema } from './common/processed';
import { EventStepType } from './eventStep';
import { EventTemplateFieldSchema } from './eventField';
import { EventTemplateStatusSchema } from './eventTemplateStatus';
import { OptionalStringSchema } from './commonZodSchemas/strings';

export const trimAndConvertToNumber = (val: any) => {
  if (val && typeof val === 'string' && val.trim()) {
    return Number(val.trim());
  }

  return val;
};

export const EventTemplateStep = z.object({
  id: NumberSchema,
  workflow_id: z.number(),
  label: z.string(),
  template_id: z.number().nullable(),
  form_template_id: z.number().nullable(),
  succeeds: z.number().nullable(),
  precedes: z.number().nullable(),
  order: z.number(),
  start_time_constraint: z.number().nullable(),
  type: EventStepType,
});

export const EventTemplateStepCreateEdit = EventTemplateStep.extend({
  id: EventTemplateStep.shape.id.nullable(),
  workflow_id: EventTemplateStep.shape.workflow_id.nullable(),
  start_time_constraint: EventTemplateStep.shape.start_time_constraint.nullable(),
});

export const EventTemplateDetailsResponse = z.object({
  id: NumberSchema,
  company_id: z.number(),
  name: z.string(),
  prefix: z.string(),
  sequence_id: z.number(),
  active: z.boolean(),
  default_time_limit: z.number(),
  default_owner_id: z.number().optional().nullable(),
  default_owner_has_permission: z.boolean().optional().nullable(),
  updated_at: DateSchema.nullable(),
  fields: z.array(EventTemplateFieldSchema),
  steps: z.array(EventTemplateStep),
  status: EventTemplateStatusSchema,
  lineage_id: z.string(),
  major_version: z.number(),
  minor_version: z.number(),
  owner: z.string().nullable(),
  owner_id: z.number().nullable(),
});

export const EventTemplateCreateEditBaseSchema = z.object({
  id: NumberSchema.optional(),
  name: z
    .string({ required_error: 'Name is required' })
    .trim()
    .nonempty({ message: 'Name must contain at least 1 character' }),
  prefix: z
    .string({ required_error: 'Prefix is required' })
    .trim()
    .nonempty({ message: 'Prefix must contain at least 1 character' }),
  default_owner_id: z
    .preprocess(
      trimAndConvertToNumber,
      z
        .number({ invalid_type_error: 'Default owner id must be a number' })
        .int({ message: 'The number must be a positive integer' })
        .min(0, { message: 'Minimum default owner should be 0' }),
    )
    .optional()
    .nullable(),
  default_time_limit: z.preprocess(
    trimAndConvertToNumber,
    z
      .number({ invalid_type_error: 'Time limit is required and must be a number' })
      .int({ message: 'The number must be a positive integer' })
      .max(100000, { message: 'Maximum time limit should be 100000 days' })
      .min(0, { message: 'Minimum time limit should be 0 days' }),
  ),
  start_time_constraint: z.preprocess(
    trimAndConvertToNumber,
    z
      .number({ invalid_type_error: 'Validation step time delay is required and must be a number' })
      .int({ message: 'The number must be a positive integer' })
      .min(0, { message: 'Minimum validation step time delay should be 0 days' }),
  ),
});

export const EventTemplateCreateEdit = EventTemplateCreateEditBaseSchema.extend({
  fields: z.array(EventTemplateFieldSchema),
  steps: z.array(EventTemplateStepCreateEdit),
});

export type EventTemplateDetailsResponse = z.infer<typeof EventTemplateDetailsResponse>;
export type EventTemplateCreateEditBase = z.infer<typeof EventTemplateCreateEditBaseSchema>;
export type EventTemplateCreateEdit = z.infer<typeof EventTemplateCreateEdit>;
export type EventTemplateStep = z.infer<typeof EventTemplateStep>;
export type EventTemplateStepCreateEdit = z.infer<typeof EventTemplateStepCreateEdit>;

export const EventTemplateFormFieldSchema = z.record(z.string(), EventTemplateFieldSchema);

export type EventTemplateFormFieldValues = z.infer<typeof EventTemplateFormFieldSchema>;

export const EventTemplateFormSchema = z.object({
  name: z
    .string({ required_error: 'Name is required' })
    .trim()
    .min(1, { message: 'Name must contain at least 1 character' }),
  prefix: z
    .string({ required_error: 'Prefix is required' })
    .trim()
    .min(1, { message: 'Prefix must contain at least 1 character' }),
  default_owner_id: OptionalStringSchema.default(''),
  default_time_limit: z.preprocess(
    trimAndConvertToNumber,
    z
      .number({ invalid_type_error: 'Time limit is required and must be a number' })
      .int({ message: 'The number must be a positive integer' })
      .max(100000, { message: 'Maximum time limit should be 100000 days' })
      .min(0, { message: 'Minimum time limit should be 0 days' }),
  ),
  start_time_constraint: z.preprocess(
    trimAndConvertToNumber,
    z
      .number({ invalid_type_error: 'Validation step time delay is required and must be a number' })
      .int({ message: 'The number must be a positive integer' })
      .min(0, { message: 'Minimum validation step time delay should be 0 days' }),
  ),
  fields: EventTemplateFormFieldSchema,
  status: z.string().optional(),
  lineage_id: z.string(),
  steps: z.array(EventTemplateStepCreateEdit).min(1, { message: 'Template must contain at least 1 step' }),
});

export type EventTemplateFormValues = z.infer<typeof EventTemplateFormSchema>;
