import React, { useCallback } from 'react';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
  QFormControl,
  QBox,
  QSelect,
  useCurrentUser,
} from '@qualio/ui-components';
import {
  ChangeEventTemplateOwnerSuccessMessage,
  ChangeTemplateOwner,
  CurrentEventTemplateOwnerLabel,
  DefaultErrorMessage,
  Failed,
  Save,
  Success,
} from '../../displayStrings';
import { useUsersOptions } from '../../hooks/useUsersOptions';
import { useQuery } from 'react-query';
import usersApi from '../../api/users.api';

type ChangeEventTemplateOwnerModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentOwnerName: string;
  currentOwnerId: string;
};

const ChangeEventTemplateOwnerModal: React.FC<ChangeEventTemplateOwnerModalProps> = ({
  isOpen,
  setIsOpen,
  currentOwnerName,
  currentOwnerId,
}) => {
  const { companyId } = useCurrentUser();

  const { data: usersData } = useQuery(['usersList', companyId], () =>
    usersApi.getAllUsers(companyId, { status: 'accepted' }),
  );

  const usersOptions = useUsersOptions(usersData);

  const { showToast } = useToastProvider();

  const handleClose = () => {
    setIsOpen(false);
  };

  const save = useCallback(() => {
    try {
      setIsOpen(false);
      showToast({
        title: Success,
        description: ChangeEventTemplateOwnerSuccessMessage,
        status: 'success',
      });
    } catch (err: any) {
      showToast({
        title: Failed,
        description: DefaultErrorMessage,
        status: 'error',
      });
    }
  }, [showToast, setIsOpen]);

  return (
    <QModal data-cy={'change-owner-modal'} isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>{ChangeTemplateOwner}</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <QFormControl label={CurrentEventTemplateOwnerLabel}>
            <QText>{currentOwnerName}</QText>
          </QFormControl>
          <QFormControl label="New template owner">
            <QBox data-cy="change-owner-select">
              <QSelect
                onChange={(changeObject) => {
                  console.info(changeObject?.value);
                }}
                options={usersOptions}
                value={currentOwnerId}
              />
            </QBox>
          </QFormControl>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          Cancel
        </QButton>
        <QButton onClick={save}>{Save}</QButton>
      </QModalActions>
    </QModal>
  );
};

export default ChangeEventTemplateOwnerModal;
