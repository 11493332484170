import React from 'react';
import { useCurrentUser, QSpinner } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';
import EventTemplateForm from './components/EventTemplateForm';
import { useGetUserTimezone, useTemplateQuery } from './hooks';
import { useDocTitle } from '../../hooks';
import { DefaultFormValues } from './formHelpers';
import { useQuery } from 'react-query';
import usersApi from '../../api/users.api';
import { filterByPermissionButInclude } from '../../utils/userUtils';

type EventTemplateProps = {
  viewType: 'new' | 'edit';
};

const EventTemplate: React.FC<EventTemplateProps> = ({ viewType }) => {
  const { companyId, userId } = useCurrentUser();
  const { eventTemplateId } = useParams();
  const isEditView = viewType === 'edit';
  const templateQuery = useTemplateQuery(companyId, eventTemplateId, isEditView);
  const timeZoneQuery = useGetUserTimezone(userId, companyId);
  const { data: allUsers, isLoading: isAllUserLoading } = useQuery(['userList', companyId], () =>
    usersApi.getAllUsers(companyId, { status: 'accepted' }),
  );

  useDocTitle(
    isEditView
      ? `${templateQuery.data?.eventTemplateFormValues.name} - Event templates - Qualio`
      : 'Event templates - Qualio',
  );

  // When in edit mode we need to wait for template to load after mount, otherwise we can render immediately
  const templateIsLoading = isEditView ? templateQuery.isLoading || !templateQuery.isFetchedAfterMount : false;

  if (templateIsLoading || timeZoneQuery.isLoading || timeZoneQuery.isIdle || !allUsers || isAllUserLoading) {
    return <QSpinner />;
  }

  if (templateQuery.isError || timeZoneQuery.isError) {
    throw new Error('Could not find event template or user timezone');
  }

  // templateQuery.data should always exist here if we are in edit view and aren't loading, but TS doesn't know that
  const eventTemplateFormValues =
    isEditView && templateQuery.data ? templateQuery.data.eventTemplateFormValues : DefaultFormValues;

  const possibleOwners = filterByPermissionButInclude(
    allUsers,
    'can_work_on_issue',
    eventTemplateFormValues.default_owner_id,
  );

  return (
    <EventTemplateForm
      initialValues={eventTemplateFormValues}
      possibleOwners={possibleOwners}
      companyId={companyId}
      templateId={eventTemplateId ? Number(eventTemplateId) : undefined}
      defaultOwnerHasPermission={templateQuery.data?.defaultOwnerHasPermission}
      ownerId={templateQuery.data?.ownerId}
      ownerName={templateQuery.data?.ownerName}
    />
  );
};

export default EventTemplate;
