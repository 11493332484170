import {
  QualityEvent,
  QualityEventListResponse,
  QualityEventsGet,
  QualityEventCreate,
  QualityEventSync,
} from '../types';
import api, { createRequestConfig, medtechApi } from './index';

const eventsApi = {
  async getAllEvents(company: number, filterParams?: QualityEventsGet): Promise<QualityEventListResponse | undefined> {
    const res = await api.get(
      `/company/${company}/events`,
      createRequestConfig({
        params: filterParams,
      }),
    );
    return QualityEventListResponse.parse(res.data);
  },

  async create(company: number, payload: QualityEventCreate): Promise<QualityEvent> {
    const response = await api.post(`/company/${company}/events`, {
      ...payload,
    });
    return QualityEvent.parse(response.data.data);
  },

  async update(company: number, eventId: number, payload: QualityEventCreate): Promise<void> {
    const response = await api.patch(`/company/${company}/events/${eventId}`, {
      ...payload,
      id: eventId,
    });
    return response.data;
  },

  async syncTemplateFields(company_id: number, eventId: number, templateId: number): Promise<QualityEventSync> {
    const response = await medtechApi.post(`/quality-events/${company_id}/issues/sync`, {
      issue_id: eventId,
      workflow_id: templateId,
    });
    return response.data;
  },
};

export default eventsApi;
