import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  QMenuButton,
  QMenuItem,
  QIconButton,
  QBox,
  useToastProvider,
  QToastProps,
  useCurrentUser,
} from '@qualio/ui-components';
import { EventTemplateStepCreateEdit, EventTemplateFormValues } from '../../../types';
import { moveElementInOrderedArray } from '../../../utils/arrayUtils';
import { useFormTemplateDetails } from '../../../hooks';
import { useFlags } from '../../../external/LaunchDarklyAdapter';

type StepRowActionsProps = {
  step: EventTemplateStepCreateEdit;
  templateSteps: EventTemplateFormValues['steps'];
  setTemplateSteps: (steps: EventTemplateFormValues['steps']) => void;
  renameTemplateStep: (stepIndex: number) => void;
  changeValidationStepType: (stepIndex: number) => void;
  onDelete: (step: EventTemplateStepCreateEdit) => void;
  disableEdit: boolean;
  isTemplateReadonly: boolean;
};

const makeSaveToast = (title: string) => {
  return {
    title: title,
    description: 'Please save the event template first',
    status: 'error',
  } as QToastProps;
};

const StepRowActions = ({
  step,
  templateSteps,
  setTemplateSteps,
  renameTemplateStep,
  changeValidationStepType,
  onDelete,
  disableEdit,
  isTemplateReadonly,
}: StepRowActionsProps) => {
  const navigate = useNavigate();
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const templateId = step.workflow_id;
  const contentTemplateId = step.template_id;
  const formTemplateId = step.form_template_id;
  const stepIsValidationStep = step.order === templateSteps.length;
  const stepCanBeValidationStep = ['document', 'form'].includes(step.type);
  const upButtonDisabled = step.order === 1 || stepIsValidationStep;
  const downButtonDisabled = step.order === templateSteps.length - 1 || stepIsValidationStep;
  const showDelete = !stepIsValidationStep;
  const showEditContentButton = disableEdit ? false : step.type === 'document' && step.template_id;
  const showEditFormButton = disableEdit ? false : step.type === 'form';
  const existingForm = templateId && step.id && formTemplateId && showEditFormButton;
  const areValStepImprovementsEnabled = useFlags('qeValidationStepImprovements');

  const { data } = useFormTemplateDetails(
    companyId,
    Number(templateId),
    Number(step.id),
    Number(formTemplateId),
    !!existingForm,
  );

  const formHasFields = data && Array.isArray(data.fields) && data.fields.length;

  const editContentAction = useCallback(() => {
    if (contentTemplateId) {
      navigate(`../templates/${templateId}/content-templates/${contentTemplateId}/edit`);
    } else {
      showToast(makeSaveToast('Content template not created'));
    }
  }, [contentTemplateId, navigate, showToast, templateId]);

  const editFormAction = useCallback(() => {
    if (formTemplateId) {
      navigate(`../templates/${templateId}/form-templates/${formTemplateId}/edit`);
    } else {
      showToast(makeSaveToast('Form template not created'));
    }
  }, [navigate, templateId, showToast, formTemplateId]);

  const changeStepOrder = (direction: 'up' | 'down') => {
    setTemplateSteps(moveElementInOrderedArray(templateSteps, step, direction));
  };

  const makeValidationStep = () => {
    setTemplateSteps(moveElementInOrderedArray(templateSteps, step, 'last'));
  };

  const deleteAction = () => {
    onDelete(step);
  };

  const renameAction = () => {
    renameTemplateStep(step.order - 1);
  };

  const changeValidationTypeAction = () => {
    changeValidationStepType(step.order - 1);
  };

  const changeStepOrderUp = () => {
    changeStepOrder('up');
  };

  const changeStepOrderDown = () => {
    changeStepOrder('down');
  };

  return (
    <QBox textAlign="right">
      {stepIsValidationStep ? null : (
        <QIconButton
          data-cy={`arrow-up-${step.id}`}
          iconName="ArrowUp"
          aria-label="moveUp"
          onClick={changeStepOrderUp}
          isDisabled={isTemplateReadonly || upButtonDisabled}
        />
      )}
      {stepIsValidationStep ? null : (
        <QIconButton
          data-cy={`arrow-down-${step.id}`}
          iconName="ArrowDown"
          aria-label="moveDown"
          onClick={changeStepOrderDown}
          isDisabled={isTemplateReadonly || downButtonDisabled}
        />
      )}
      <QMenuButton
        isDisabled={isTemplateReadonly}
        buttonLabel={`step-actions-${step.id}`}
        iconName="MoreVertical"
        itemSize="sm"
        variant="icon"
      >
        <QMenuItem data-cy={`rename-step-action-${step.id}`} onClick={renameAction} aria-label="rename-action">
          Rename step
        </QMenuItem>
        {stepIsValidationStep && !templateId ? (
          <QMenuItem
            data-cy={`change-validation-type-action-${step.id}`}
            onClick={changeValidationTypeAction}
            aria-label="change-validation-type-action"
          >
            Change validation step type
          </QMenuItem>
        ) : null}
        {showEditContentButton ? (
          <QMenuItem
            data-cy={`edit-content-template-action-${step.id}`}
            onClick={editContentAction}
            aria-label="edit-action"
          >
            Edit content template
          </QMenuItem>
        ) : null}
        {showEditFormButton ? (
          <QMenuItem data-cy={`edit-form-action-${step.id}`} onClick={editFormAction} aria-label="edit-form-action">
            {formHasFields ? 'Edit form template' : 'Start building your form'}
          </QMenuItem>
        ) : null}
        {showDelete ? (
          <QMenuItem data-cy={`delete-step-action-${step.id}`} onClick={deleteAction} aria-label="delete-action">
            Delete step
          </QMenuItem>
        ) : null}
        {!stepIsValidationStep && stepCanBeValidationStep && areValStepImprovementsEnabled ? (
          <QMenuItem
            data-cy={`make-validation-action-${step.id}`}
            onClick={makeValidationStep}
            aria-label="make-validation-action"
          >
            Make this the validation step
          </QMenuItem>
        ) : null}
      </QMenuButton>
    </QBox>
  );
};

export default StepRowActions;
