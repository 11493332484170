import React, { useCallback } from 'react';
import { TableMenuItem, useCurrentUser } from '@qualio/ui-components';
import { useArchiveTemplate, useRestoreTemplate, useCreateNewDraftTemplate } from './hooks';
import { EventType } from '../../types';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import * as DisplayStrings from '../../displayStrings';
import { useUserRole } from '../../hooks/useUserRole';
import { EventTemplatePermissions } from '../../utils/eventTemplatePermissionUtils';

type TemplateRowActionsProps = {
  companyId: number;
  setTemplateForMakeEffective: (templateId: number, lineageId: string) => void;
  setTemplateForDeleteDraft: (templateId: number, lineageId: string) => void;
};

const TemplateRowActions = ({
  companyId,
  setTemplateForMakeEffective,
  setTemplateForDeleteDraft,
}: TemplateRowActionsProps) => {
  const { mutate: archiveTemplate } = useArchiveTemplate(companyId);
  const { mutate: restoreTemplate } = useRestoreTemplate(companyId);
  const { mutate: createNewDraftTemplate } = useCreateNewDraftTemplate(companyId);
  const qeTemplateChangeManagement = useFlags('qeTemplateChangeManagement');
  const { userId, permissions } = useCurrentUser();
  const role = useUserRole();

  const isDeleteDraftDisabled = useCallback(
    (template: EventType): boolean => {
      const { canDeleteDraftOrChangeOwner } = EventTemplatePermissions(
        template.status,
        template.ownerId,
        userId,
        role,
        Boolean(permissions['can_manage_quality_event_workflow']),
      );
      return !canDeleteDraftOrChangeOwner;
    },
    [userId, permissions, role],
  );

  return (
    <>
      <TableMenuItem
        data-cy="archive-template"
        onClick={(template: EventType) => archiveTemplate(template.id)}
        isDisabled={(template: EventType) => template.status !== 'effective' || !template.active}
      >
        Archive
      </TableMenuItem>
      <TableMenuItem
        data-cy="restore-template"
        onClick={(template: EventType) => restoreTemplate(template.id)}
        isDisabled={(template: EventType) => template.active}
      >
        Restore
      </TableMenuItem>
      {qeTemplateChangeManagement && (
        <>
          <TableMenuItem
            data-cy="create-new-draft-template"
            onClick={(template: EventType) => createNewDraftTemplate(template.lineage_id!)}
            isDisabled={(template: EventType) =>
              !['effective', 'for_approval', 'approval_declined'].includes(template.status!) || !template.active
            }
          >
            {DisplayStrings.CreateNewDraft}
          </TableMenuItem>
          <TableMenuItem
            data-cy="make-template-effective"
            onClick={(template: EventType) => setTemplateForMakeEffective(Number(template.id), template.lineage_id)}
            isDisabled={(template: EventType) => template.status !== 'draft' || template.ownerId !== userId}
          >
            {DisplayStrings.MakeEffective}
          </TableMenuItem>
          <TableMenuItem
            data-cy="delete-draft-template"
            onClick={(template: EventType) => setTemplateForDeleteDraft(Number(template.id), template.lineage_id)}
            isDisabled={isDeleteDraftDisabled}
          >
            {DisplayStrings.DeleteDraft}
          </TableMenuItem>
        </>
      )}
    </>
  );
};

export default TemplateRowActions;
