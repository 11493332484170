import React, { useCallback } from 'react';
import { useCurrentUser } from '@qualio/ui-components';
import { Purpose } from '../../displayStrings';
import DigitalSignatureModal from '../DigitalSignatureModal/DigitalSignatureModal';
import { DigitalSignatureInput, DigitalSignatureV2 } from '../../types/digitalSignature';
import * as DisplayStrings from '../../displayStrings';
import { useMutation, useQueryClient } from 'react-query';
import templatesApi from '../../api/templates.api';
import { baseEventTemplateQueryKey } from '../../hooks';
import { BASE_QUERY_KEY } from '../../views/QualityEventTemplates/hooks';

type MakeEffectiveModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  lineageId: string;
  templateId: number;
};

const makeEffectiveModalInput: DigitalSignatureInput = {
  headingText: DisplayStrings.MakeEffectiveModalHeadingText,
  subText: DisplayStrings.MakeEffectiveModalSubText,
  submitButtonText: DisplayStrings.MakeEffective,
  successToastHeader: DisplayStrings.Success,
  successToastDescription: DisplayStrings.MakeEffectiveSuccessMessage,
};

const MakeEffectiveModal = ({ isOpen, setIsOpen, lineageId, templateId }: MakeEffectiveModalProps) => {
  const { companyId } = useCurrentUser();
  const queryClient = useQueryClient();

  const { mutate: makeEventEffectiveMutate } = useMutation(
    (payload: DigitalSignatureV2) => templatesApi.makeTemplateEffective(companyId, lineageId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([baseEventTemplateQueryKey, companyId, String(templateId)]);
        queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]);
      },
    },
  );

  const handleMakeEffective = useCallback(
    async (payload: DigitalSignatureV2) => {
      makeEventEffectiveMutate(payload);
    },
    [makeEventEffectiveMutate],
  );

  return (
    <DigitalSignatureModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSave={handleMakeEffective}
      inputTexts={makeEffectiveModalInput}
      isCommentRequired={true}
      commentLabel={Purpose}
    />
  );
};

export default MakeEffectiveModal;
