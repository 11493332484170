import * as z from 'zod';

import { EventTemplateStatusSchema } from './eventTemplateStatus';

export const EventTypeSchema = z
  .object({
    id: z.string(),
    active: z.boolean(),
    can_be_archived: z.boolean(),
    company_id: z.number(),
    default_time_limit: z.number(),
    issues_count: z.number().optional(),
    name: z.string(),
    prefix: z.string(),
    sequence_id: z.number(),
    updated_at: z.string().nullable().optional(),
    default_owner_id: z.number().nullable().optional(),
    default_owner: z.string().nullable().optional(),
    status: EventTemplateStatusSchema,
    lineage_id: z.string(),
    major_version: z.number(),
    minor_version: z.number(),
    owner_id: z.number().nullable().optional(),
  })
  .transform((val) => {
    return {
      id: val.id,
      active: val.active,
      canBeArchived: val.can_be_archived,
      companyId: val.company_id,
      defaultTimeLimit: val.default_time_limit,
      issuesCount: val.issues_count,
      name: val.name,
      prefix: val.prefix,
      sequenceId: val.sequence_id,
      updatedAt: val.updated_at,
      defaultOwnerId: val.default_owner_id,
      defaultOwner: val.default_owner,
      status: val.status,
      lineage_id: val.lineage_id,
      major_version: val.major_version,
      minor_version: val.minor_version,
      ownerId: val.owner_id,
    };
  });

export type EventType = z.infer<typeof EventTypeSchema>;
