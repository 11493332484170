import React from 'react';
import { QTag, QTagProps } from '@qualio/ui-components';
import { EventTemplateStatus } from '../../../types';
import * as DisplayStrings from '../../../displayStrings';

const statusToTagProps: { [key in EventTemplateStatus]?: QTagProps } = {
  draft: {
    variantColor: 'gray',
    children: DisplayStrings.TemplateDraft,
  },
  effective: {
    variantColor: 'green',
    children: DisplayStrings.TemplateEffective,
  },
  archived: {
    variantColor: 'red',
    children: DisplayStrings.TemplateArchived,
  },
};

type EventTemplateStatusTagProps = {
  status: EventTemplateStatus;
};

const EventTemplateStatusTag = ({ status }: EventTemplateStatusTagProps) => {
  return (
    <QTag
      aria-label="event-template-status-tag"
      {...(statusToTagProps[status] ?? { variantColor: 'default', children: status })}
    />
  );
};

export default EventTemplateStatusTag;
