import React, { useCallback, useEffect, useRef, useState } from 'react';

import { QAlert, QBox, QButton, QFlex, useCurrentUser, useToastProvider } from '@qualio/ui-components';

import eventsApi from '../../api/events.api';
import { useEventTemplateFormValues } from '../../hooks';
import { EventTemplateFieldAttributeNames, EventTemplateFields, EventTemplateFormValues } from '../../types';
import { compare } from '../../utils/arrayUtils';
import { camelOrSnakeToTitle } from '../../utils/textUtils';

type UpdateTemplateFieldsAlertProps = {
  ownerId: number;
  eventTemplateId: number;
  existingTemplateFields?: EventTemplateFields;
  eventId: number;
  updateEventTemplateFields: (templateFields: EventTemplateFields) => void;
};

const UpdateTemplateFieldsAlert: React.FC<UpdateTemplateFieldsAlertProps> = ({
  eventTemplateId,
  existingTemplateFields,
  eventId,
  updateEventTemplateFields,
  ownerId,
}) => {
  const { companyId, userId } = useCurrentUser();
  const isOwner = ownerId === userId;
  const templateResult = useEventTemplateFormValues(companyId, eventTemplateId);
  const { showToast } = useToastProvider();

  const [addedFields, setAddedFields] = useState<string[]>([]);
  const [removedFields, setRemovedFields] = useState<string[]>([]);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [notRequiredFields, setNotRequiredFields] = useState<string[]>([]);

  const fieldsFromTemplateKeys = Object.keys(templateResult.data?.fields ?? {});

  const hasTemplateFields = () => {
    return (
      (existingTemplateFields && existingTemplateFields.length !== 0) ||
      (templateResult.data?.fields && fieldsFromTemplateKeys.length !== 0)
    );
  };

  const hasTemplateFieldChanges = () => {
    return (
      addedFields.length > 0 || removedFields.length > 0 || requiredFields.length > 0 || notRequiredFields.length > 0
    );
  };

  const templateFieldRef = useRef((templateResult.data as EventTemplateFormValues)?.fields);

  // decide if we should actually update the reference for useEffectUpdate
  if (!compare(Object.keys(templateFieldRef.current ?? {}), fieldsFromTemplateKeys)) {
    templateFieldRef.current = (templateResult.data as EventTemplateFormValues)?.fields;
  }

  const fieldsFromTemplate = templateFieldRef.current;

  useEffect(() => {
    if (templateResult.isLoading) {
      return;
    }

    const keys = Object.keys(EventTemplateFieldAttributeNames.Enum);

    const added: string[] = [];
    const removed: string[] = [];
    const required: string[] = [];
    const notRequired: string[] = [];

    keys.forEach((key) => {
      const readableKey = camelOrSnakeToTitle(key);
      const existingHasField = existingTemplateFields?.find((existingField) => existingField.attribute_name === key);
      const newHasField = fieldsFromTemplate?.[key];

      if (Boolean(existingHasField) !== Boolean(newHasField)) {
        if (existingHasField) {
          removed.push(readableKey);
        } else {
          const requiredFlag = newHasField?.mandatory ? ' as required' : ' as not required';
          added.push(`${readableKey}${requiredFlag}`);
        }
      } else if (Boolean(existingHasField?.mandatory) !== Boolean(newHasField?.mandatory)) {
        if (existingHasField?.mandatory) {
          notRequired.push(readableKey);
        } else {
          required.push(readableKey);
        }
      }
    });

    if (removed.length > 0) {
      setRemovedFields(removed);
    }
    if (added.length > 0) {
      setAddedFields(added);
    }
    if (notRequired.length > 0) {
      setNotRequiredFields(notRequired);
    }
    if (required.length > 0) {
      setRequiredFields(required);
    }
  }, [existingTemplateFields, templateResult.isLoading, fieldsFromTemplate]);

  const sync = useCallback(async () => {
    try {
      const qualityEventSyncResponse = await eventsApi.syncTemplateFields(companyId, eventId, eventTemplateId);
      updateEventTemplateFields(qualityEventSyncResponse.template_fields);

      showToast({
        title: 'Event updated',
        description: 'Event has been updated to the latest event template.',
        status: 'success',
      });
    } catch {
      showToast({
        title: 'Event not updated',
        description: 'Event has not been updated to the latest event template.',
        status: 'error',
      });
    }
  }, [companyId, eventId, eventTemplateId, updateEventTemplateFields, showToast]);

  if (!hasTemplateFields() || !hasTemplateFieldChanges()) {
    return null;
  }

  const alertDescription = (
    <QBox marginLeft={5}>
      <QFlex justify="space-between">
        <ul>
          {(removedFields.length && <li>Removed from properties: {removedFields.join(', ')}</li>) || ''}
          {(addedFields.length && <li>Added to properties: {addedFields.join(', ')}</li>) || ''}
          {(requiredFields.length && <li>Required: {requiredFields.join(', ')}</li>) || ''}
          {(notRequiredFields.length && <li>Not required: {notRequiredFields.join(', ')}</li>) || ''}
        </ul>
        {isOwner ? (
          <QFlex align="center">
            <QButton aria-label="Update event" size="sm" variant="outline" onClick={sync}>
              Update event
            </QButton>
          </QFlex>
        ) : null}
      </QFlex>
    </QBox>
  );

  return (
    <QAlert
      title="Updates have been made to this template"
      status="warning"
      description={alertDescription}
      marginTop={4}
    ></QAlert>
  );
};

export default UpdateTemplateFieldsAlert;
