import {
  EventTemplateFieldAttributeName,
  EventTemplateFieldAttributeNames,
  EventTemplateFields,
} from '../types/eventField';
import { EventType, EventTemplateStatusSchema, EventTemplateStatus } from '../types';
import {
  TemplateDraft,
  TemplateEffective,
  TemplateArchived,
  TemplateForApproval,
  TemplateApprovalDeclined,
  TemplateSuperseded,
} from '../displayStrings';

export const getBlankStep = () => {
  return {
    id: null,
    workflow_id: null,
    template_id: null,
    succeeds: null,
    precedes: null,
    order: Infinity,
    start_time_constraint: null,
    form_template_id: null,
  };
};

export const isFieldVisible = (
  templateFields: EventTemplateFields,
  fieldName: EventTemplateFieldAttributeName,
): boolean => {
  if (templateFields === null || templateFields === undefined) {
    return new Set([
      EventTemplateFieldAttributeNames.Enum.product,
      EventTemplateFieldAttributeNames.Enum.root_cause,
      EventTemplateFieldAttributeNames.enum.risk,
    ] as string[]).has(fieldName);
  }

  return templateFields.some((field) => field.attribute_name === fieldName);
};

export const isFieldRequired = (
  templateFields: EventTemplateFields,
  isConfigurableFieldsEnabled: boolean,
  fieldName: EventTemplateFieldAttributeName,
): boolean => {
  if (!isConfigurableFieldsEnabled || templateFields === null || templateFields === undefined) {
    return fieldName === 'risk'; // by default only risk is required
  }

  const field = templateFields.find((field) => field.attribute_name === fieldName);

  return field ? field.mandatory : false;
};

export const statusToDisplayString: { [key in EventTemplateStatus]: string } = {
  draft: TemplateDraft,
  effective: TemplateEffective,
  archived: TemplateArchived,
  for_approval: TemplateForApproval,
  approval_declined: TemplateApprovalDeclined,
  superseded: TemplateSuperseded,
};

export const templateStatus = (template: EventType, qeTemplateChangeManagement: boolean): EventTemplateStatus => {
  if (!template.active) {
    return EventTemplateStatusSchema.Enum.archived;
  }
  return qeTemplateChangeManagement ? template.status : EventTemplateStatusSchema.Enum.effective;
};
